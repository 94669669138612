<template>
  <div class="contractdetail">
    <el-row :gutter="2">
      <el-col :span="6">
        <div class="grid-content">
          <span class="label">合同编号: </span>
          <span class="value">{{ contractDetail.contract_id }}</span>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content">
          <span class="label">合同名称: </span>
          <span class="value">{{ contractDetail.contract_name }}</span>
        </div>
      </el-col>
      <el-col :span="4">
        <div class="grid-content">
          <span class="label">签约时间: </span>
          <span class="value">{{ contractDetail.signing_date }}</span>
        </div>
      </el-col>
      <el-col :span="4">
        <div class="grid-content">
          <span class="label">签约人: </span>
          <span class="value">{{ contractDetail.contractor }}</span>
        </div>
      </el-col>
      <el-col :span="4">
        <div class="grid-content">
          <span class="label">乙方法人: </span>
          <span class="value">{{ contractDetail.faren }}</span>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="2">
      <el-col :span="6">
        <div class="grid-content">
          <span class="label">甲方单位: </span>
          <span class="value">{{ contractDetail.first_party }}</span>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content">
          <span class="label">乙方单位: </span>
          <span class="value">{{ contractDetail.second_party }}</span>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content">
          <span class="label">施工地点: </span>
          <span class="value">{{ contractDetail.project }}</span>
        </div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content">
          <span class="label">联系人: </span>
          <span class="value">{{ contractDetail.contact }}</span>
        </div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content">
          <span class="label">联系电话: </span>
          <span class="value">{{ contractDetail.contact_phone }}</span>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="2">
      <el-col :span="3">
        <div class="grid-content" style="height:40px;">
          <span class="label">合同金额: </span>
          <span class="value">{{ contractDetail.contract_amount }}</span>
        </div>
      </el-col>
      <!-- <el-col :span="3">
        <div class="grid-content">
          <span class="label">实际金额: </span>
          <span class="value">{{ contractDetail.real_amount }}</span>
        </div>
      </el-col> -->
      <el-col :span="3">
        <div class="grid-content" style="height:40px;">
          <span class="label">回款负责人: </span>
          <span class="value">{{ contractDetail.collection_person_name }}</span>
        </div>
      </el-col>
      <el-col :span="15">
        <div class="grid-content" style="height:40px;width:100%;overflow:auto;line-height:20px">
          <span class="label">施工材料: </span>
          <span class="value">{{ contractDetail.construction_material }}</span>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="2">
      <el-col :span="6">
        <div class="grid-content">
          <span class="label">法务审核: </span>
          <el-tag size="mini" :type="!contractDetail.legal_audit ? 'primary' : 'success'">{{ !contractDetail.legal_audit ? '未审核' : '已审核' }}</el-tag>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content">
          <span class="label">审核人: </span>
          <span class="value">{{ contractDetail.legal_audit }}</span>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content">
          <span class="label">审核时间: </span>
          <span class="value">{{ contractDetail.audit_time }}</span>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="2" v-if="false">
      <el-col :span="6">
        <div class="grid-content">
          <span class="label">财务审核: </span>
          <el-tag size="mini" :type="!contractDetail.financial_audit ? 'primary' : 'success'">{{
            !contractDetail.financial_audit ? '未审核' : '已审核'
          }}</el-tag>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content">
          <span class="label">审核人: </span>
          <span class="value">{{ contractDetail.financial_audit }}</span>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content">
          <span class="label">审核时间: </span>
          <span class="value">{{ contractDetail.financial_audit_time }}</span>
        </div>
      </el-col>
    </el-row>

    <el-row :gutter="2">
      <el-col :span="6">
        <div class="grid-content">
          <span class="label">结单状态: </span>
          <el-tag size="mini" :type="!contractDetail.is_statement ? 'primary' : 'success'">{{ !contractDetail.is_statement ? '未结单' : '已结单' }}</el-tag>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content">
          <span class="label">结单人: </span>
          <span class="value">{{ contractDetail.statement_person }}</span>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content">
          <span class="label">结单时间: </span>
          <span class="value">{{ contractDetail.statement_person_time }}</span>
        </div>
      </el-col>
    </el-row>

    <el-row :gutter="2">
      <el-col :span="6">
        <div class="grid-content">
          <span class="label">财务确认: </span>
          <el-tag size="mini" :type="!contractDetail.financial_confirm ? 'primary' : 'success'">{{
            !contractDetail.financial_confirm ? '未确认' : '已确认'
          }}</el-tag>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content">
          <span class="label">确认人: </span>
          <span class="value">{{ contractDetail.financial_confirm_person }}</span>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content">
          <span class="label">确认时间: </span>
          <span class="value">{{ contractDetail.financial_confirm_time }}</span>
        </div>
      </el-col>
    </el-row>

    <el-row :gutter="2">
      <el-col :span="6">
        <div class="grid-content">
          <span class="label">添加人: </span>
          <span class="value">{{ contractDetail.added_person }}</span>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content">
          <span class="label">添加时间: </span>
          <span class="value">{{ contractDetail.added_person_time }}</span>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  props: {
    contractDetail: Object,
    userType: Object
  }
}
</script>
